import React from "react"
import "styles/pages/404.scss"

// Components
import Layout from "components/Layout"
import SEO from "components/SEO/"

export default function PageNotFound() {

  return (
    <Layout>
      <SEO title="Page Not Found" />
      <div className="page-404 container">
        <div className="title">The page you’re looking for can’t be found.</div>
      </div>
    </Layout>
  );
}